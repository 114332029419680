<template>

    <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse transactions">
        <div class="overlay pt-120">
            <div class="container-fruid">
                <div class="head-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-4">
                            <h4>Trades</h4>
                        </div>
                        <div class="col-lg-7 col-md-8">
<!--                            <div class="transactions-right d-flex align-items-center">-->
<!--                                <form action="#" class="flex-fill">-->
<!--                                    <div class="form-group d-flex align-items-center">-->
<!--                                        <img src="../../../public/assets/dashboard/images/icon/search.png" alt="icon">-->
<!--                                        <input type="text" placeholder="Type to search...">-->
<!--                                    </div>-->
<!--                                </form>-->
<!--&lt;!&ndash;                                <a href="javascript:void(0)">Monthly Statement</a>&ndash;&gt;-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="transactions-main">
                            <div class="top-items">
                                <h6>New Trade</h6>
                                <div class="export-area">
                                    <ul class="d-flex align-items-center">

                                    </ul>
                                </div>
                            </div>

<!--                            <div class="table-responsive">-->
                              <form class="form" @submit.prevent="createTrade()" style="margin-left: 20px; margin-right: 20px;">
                              <div class="row">
                                  <div class="col-sm-6 pl-2">
                                    <div class="form-group">
                                      <label class="form-label">Base Currency</label>
                                      <select v-model="form.base_currency" class="form-control">
                                        <option disabled value=""> Select one</option>
                                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
                                      </select>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Select Client</label>
                                      <select v-model="form.client" class="form-control">
                                        <option disabled value="">Select client</option>
                                        <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                                      </select>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Liquidity Rate</label>
                                      <input type="number" v-model="form.l_rate" step="any" class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Volume in Base Currency(USDT)</label>
                                      <input type="number" v-model="form.base_volume" class="form-control"/>
                                    </div>

<!--                                    <div class="form-group">-->
<!--                                      <label class="form-label">Volume in Base Currency(USDT)</label>-->
<!--                                      <input type="number" v-model="form.base_volume" class="form-control"/>-->
<!--                                    </div>-->

<!--                                    <div class="form-group">-->
<!--                                      <label class="form-label">Volume in Base Currency(USDT)</label>-->
<!--                                      <input type="number" v-model="form.base_volume" class="form-control"/>-->
<!--                                    </div>-->

                                    <br>
                                    <div class="form-group">
                                      <button class="btn btn-success" type="submit">Submit</button>
                                    </div>
                                  </div>

                                  <div class="col-sm-6 pr-2">
                                    <div class="form-group">
                                      <label class="form-label">Counter Currency</label>
                                      <select v-model="form.counter_currency" class="form-control">
                                        <option disabled value=""> Select one</option>
                                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{ currency.name }}</option>
                                      </select>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Select Liquidity Provider</label>
                                     <select v-model="form.liquidity_provider" class="form-control">
                                        <option disabled value="">Select client</option>
                                        <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                                      </select>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Client Rate</label>
                                      <input type="number" v-model="form.c_rate" step="any" class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Volume in Local Currency</label>
                                      <input type="number" v-model="form.local_volume" class="form-control"/>
                                    </div>

                                    <div class="form-group">
                                      <label class="form-label">Notes</label>
                                      <textarea type="text" v-model="form.notes" class="form-control" rows="4">
                                      </textarea>
                                    </div>
                                  </div>
                              </div>
                                </form>
<!--                            </div>-->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->

</template>
<script>

import core_function from "@/services/core_function";

export default {
  data(){
    return{
      form: {
        "base_currency": {},
        "base_volume": 0,
        "client": {},
        "l_rate": 0,
        "c_rate": 0,
        "counter_currency": {},
        "liquidity_provider": {},
        "local_volume": 0,
        "notes": "",
        "liquidity_amount": 0,
        "client_amount": 0,
        "profit": 0
      },
      clients: [],
      currencies: [],
      newTrade: {},
    }
  },
  components: {

  },
  methods: {
    async getClients(){
      try{
        let response = await core_function.loadClients()
        this.clients = response.data
      }catch (err){
        console.log(err)
      }
    },
    async getCurrencies(){
      try{
        let response = await core_function.loadCurrencies()
        this.currencies = response.data
      }catch (err){
        console.log(err)
      }
    },
    async createTrade(){
      try{
        let response = await core_function.postTrade(this.form)
        if(response.status === 201){
          this.$router.push('/admin/trades')
        }else{
          console.log(response)
        }
      }catch (err){
        console.log(err)
      }
    }
  },
  mounted() {
    this.getClients()
    this.getCurrencies()
  },
  computed: {
    calculations(){
      if(this.form.base_currency !== "USD"){
        this.form.liquidity_amount = this.form.base_volume / this.form.l_rate
        this.form.client_amount = this.form.base_volume / this.form.c_rate

        this.form.profit = this.form.liquidity_amount - this.form.client_amount

      }else if(this.form.base_currency.initials === "USD"){
        this.form.liquidity_amount = this.form.base_volume / this.form.l_rate
        this.form.client_amount = this.form.base_volume / this.form.c_rate

        this.form.profit = this.form.liquidity_amount - this.form.client_amount
      }
    },

    computedBaseVolume() {
      if (this.form.base_volume === null && this.form.local_volume !== null && this.form.c_rate !== null) {
        return (this.form.local_volume / this.form.c_rate).toFixed(2);
      }
      return (this.form.base_volume);
    },

    computedLocalVolume() {
      if (this.form.local_volume === null && this.form.base_volume !== null && this.form.l_rate !== null) {
        return (this.form.base_volume * this.form.l_rate).toFixed(2);
      }
      return (this.form.local_volume);
    },

    computedUsdIn(){
      return (this.computedLocalVolume/this.form.l_rate).toFixed(2);
    },

    computedUsdOut(){
      return (this.computedLocalVolume/this.form.c_rate).toFixed(2);
    },

    computedProfit(){
      return (this.computedUsdIn - this.computedUsdOut).toFixed(2);
    }
  },
};
</script>
