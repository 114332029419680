<template>
  <div>
    <!-- Dashboard Section start -->
    <section class="dashboard-section body-collapse">
        <div class="overlay pt-120">
            <div class="container-fruid">

              <div class="row">
                <div class="col-sm-12">

                  <div class="">
                      <div class="row ">
                          <div class="col-xl-3 col-lg-3">
                              <div class="card l-bg-cherry">
                                  <div class="card-statistic-3 p-4">
                                      <div class="card-icon card-icon-large"></div>
                                      <div class="mb-4">
                                          <h5 class="card-title mb-0" style="color: white;">Trades</h5>
                                      </div>
                                      <div class="row align-items-center mb-2 d-flex">
                                          <div class="col-8">
                                              <h3 class="d-flex align-items-center mb-0" style="color: white;">
                                                  {{ dashboard_data.trades }}
                                              </h3>
                                          </div>
                                          <div class="col-4 text-right" style="color: white;">
                                              <span>12.5% <i class="fa fa-arrow-up"></i></span>
                                          </div>
                                      </div>
                                      <div class="progress mt-1 " data-height="8" style="height: 8px; color: white;">
                                          <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-3">
                              <div class="card l-bg-blue-dark">
                                  <div class="card-statistic-3 p-4">
                                      <div class="card-icon card-icon-large"></div>
                                      <div class="mb-4">
                                          <h5 class="card-title mb-0" style="color: white;">Clients</h5>
                                      </div>
                                      <div class="row align-items-center mb-2 d-flex">
                                          <div class="col-8">
                                              <h3 class="d-flex align-items-center mb-0" style="color: white;">
                                                  {{ dashboard_data.clients }}
                                              </h3>
                                          </div>
                                          <div class="col-4 text-right">
                                              <span>9.23% <i class="fa fa-arrow-up"></i></span>
                                          </div>
                                      </div>
                                      <div class="progress mt-1 " data-height="8" style="height: 8px;">
                                          <div class="progress-bar l-bg-green" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-3">
                              <div class="card l-bg-green-dark">
                                  <div class="card-statistic-3 p-4">
                                      <div class="card-icon card-icon-large"></div>
                                      <div class="mb-4">
                                          <h5 class="card-title mb-0" style="color: white;">Last Months Trades</h5>
                                      </div>
                                      <div class="row align-items-center mb-2 d-flex">
                                          <div class="col-8">
                                              <h3 class="d-flex align-items-center mb-0" style="color: white;">
                                                  {{ dashboard_data.last_months_trades }}
                                              </h3>
                                          </div>
                                          <div class="col-4 text-right">
                                              <span>10% <i class="fa fa-arrow-up"></i></span>
                                          </div>
                                      </div>
                                      <div class="progress mt-1 " data-height="8" style="height: 8px;">
                                          <div class="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-3 col-lg-3">
                              <div class="card l-bg-orange-dark">
                                  <div class="card-statistic-3 p-4">
                                      <div class="card-icon card-icon-large"></div>
                                      <div class="mb-4">
                                          <h5 class="card-title mb-0" style="color: white;">Current Months Trades</h5>
                                      </div>
                                      <div class="row align-items-center mb-2 d-flex">
                                          <div class="col-8">
                                              <h3 class="d-flex align-items-center mb-0" style="color: white;">
                                                  {{ dashboard_data.this_months_trades }}
                                              </h3>
                                          </div>
                                          <div class="col-4 text-right">
                                              <span>2.5% <i class="fa fa-arrow-up"></i></span>
                                          </div>
                                      </div>
                                      <div class="progress mt-1 " data-height="8" style="height: 8px;">
                                          <div class="progress-bar l-bg-cyan" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style="width: 25%;"></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

                <div class="row">
                    <div class="col-xl-8 col-lg-7">
                        <div class="section-content">

                            <div class="transactions-area mt-0">
                                <div class="section-text">
                                    <h5>Trades</h5>
                                    <p>Updated every several minutes</p>
                                </div>

                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="latest" role="tabpanel" aria-labelledby="latest-tab">

                                        <div class="table-responsive">
                                            <table class="table">
                                              <thead>
                                                <tr>
                                                  <th style="text-align: left; width: 20%">Client Name</th>
                                                  <th style="text-align: center">Currency</th>
                                                  <th style="text-align: left">Liquidity Provider</th>
                                                  <th style="text-align: left">Client Rate</th>
                                                  <th style="text-align: left">Liquidity Rate</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="trade in trades" v-bind:key="trade.id">
                                                  <td style="text-align: left; width: 20%">{{ trade.client.name }}</td>
                                                  <td style="text-align: center">{{ trade.base_currency.initials }}</td>
                                                  <td style="text-align: left">{{ trade.liquidity_provider.name }}</td>
                                                  <td style="text-align: right">{{ trade.c_rate }}</td>
                                                  <td style="text-align: right">{{ trade.l_rate }}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                        </div>

                                        <nav aria-label="Page navigation" class="d-flex justify-content-center mt-40">
                                            <pagination :records="dataCount" v-model="page" :per-page="dataPerPage" @paginate="getTrades()" :options="{'chunk': 4}">
                                            </pagination>
                                        </nav>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="side-items">

                            <div class="single-item">
                                <div class="section-text d-flex align-items-center justify-content-between">
                                    <h6>Trade Analytics</h6>
                                    <div class="select-box">
                                        <select>
                                            <option>Monthly</option>
                                            <option value="1">Jan</option>
                                            <option value="2">Feb</option>
                                            <option value="3">Mar</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="chart">
                                  <canvas id="myChart" width="400" height="300" aria-label="Trades" role="img"></canvas>
                                </div>
                            </div>
<!--                            <div class="single-item">-->
<!--                                <div class="section-text d-flex align-items-center justify-content-between">-->
<!--                                    <h6>Recipients</h6>-->
<!--                                    <div class="view-all d-flex align-items-center">-->
<!--                                        <a href="javascript:void(0)">View All</a>-->
<!--                                        <img src="../../../public/assets/dashboard/images/icon/right-arrow.png" alt="icon">-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <ul class="recipients-item">-->
<!--                                    <li>-->
<!--                                        <p class="left d-flex align-items-center">-->
<!--&lt;!&ndash;                                            <img src="../../../public/assets/dashboard/images/recipients-1.png" alt="icon">&ndash;&gt;-->
<!--                                            <span class="info">-->
<!--                                                <span>Phil King</span>-->
<!--                                                <span>08:00 AM — 19 August</span>-->
<!--                                            </span>-->
<!--                                        </p>-->
<!--                                        <p class="right">-->
<!--                                            <span> +$345</span>-->
<!--                                            <span>Payment</span>-->
<!--                                        </p>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <p class="left d-flex align-items-center">-->
<!--&lt;!&ndash;                                            <img src="assets/images/recipients-2.png" alt="icon">&ndash;&gt;-->
<!--                                            <span class="info">-->
<!--                                                <span>Debra Wilson</span>-->
<!--                                                <span>08:00 AM — 19 August</span>-->
<!--                                            </span>-->
<!--                                        </p>-->
<!--                                        <p class="right">-->
<!--                                            <span class="loss">-$850</span>-->
<!--                                            <span>Refund</span>-->
<!--                                        </p>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <p class="left d-flex align-items-center">-->
<!--&lt;!&ndash;                                            <img src="assets/images/recipients-3.png" alt="icon">&ndash;&gt;-->
<!--                                            <span class="info">-->
<!--                                                <span>Philip Henry</span>-->
<!--                                                <span>08:00 AM — 19 August</span>-->
<!--                                            </span>-->
<!--                                        </p>-->
<!--                                        <p class="right">-->
<!--                                            <span>+$2.050</span>-->
<!--                                            <span>Payment</span>-->
<!--                                        </p>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <p class="left d-flex align-items-center">-->
<!--&lt;!&ndash;                                            <img src="assets/images/recipients-4.png" alt="icon">&ndash;&gt;-->
<!--                                            <span class="info">-->
<!--                                                <span>Erin Garcia</span>-->
<!--                                                <span>08:00 AM — 19 August</span>-->
<!--                                            </span>-->
<!--                                        </p>-->
<!--                                        <p class="right">-->
<!--                                            <span> +$900</span>-->
<!--                                            <span>Payment</span>-->
<!--                                        </p>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Dashboard Section end -->

    <!-- Card Popup start -->
    <div class="card-popup">
        <div class="container-fruid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="modal fade" id="cardMod" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                </div>
                                <div class="main-content">
                                    <div class="top-area mb-40 mt-40 text-center">
                                        <div class="card-area mb-30">
                                            <img src="assets/images/visa-card-2.png" alt="image">
                                        </div>
                                        <div class="text-area">
                                            <h5>DurraFX payment Card </h5>
                                            <p>Linked: 01 Jun 2021</p>
                                        </div>
                                    </div>
                                    <div class="tab-area d-flex align-items-center justify-content-between">
                                        <ul class="nav nav-tabs mb-30" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="btn-link" id="cancel-tab" data-bs-toggle="tab"
                                                    data-bs-target="#cancel" type="button" role="tab"
                                                    aria-controls="cancel" aria-selected="false">
                                                    <img src="assets/images/icon/limit.png" alt="icon">
                                                    Set transfer limit
                                                </button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="d-none" id="limit-tab" data-bs-toggle="tab"
                                                    data-bs-target="#limit" type="button" role="tab"
                                                    aria-controls="limit" aria-selected="true"></button>
                                            </li>
                                            <li>
                                                <button>
                                                    <img src="assets/images/icon/remove.png" alt="icon">
                                                    Remove from Linked
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-content mt-30">
                                        <div class="tab-pane fade show active" id="limit" role="tabpanel"
                                            aria-labelledby="limit-tab">
                                            <div class="bottom-area">
                                                <p class="history">Transaction History : <span>20</span></p>
                                                <ul>
                                                    <li>
                                                        <p class="left">
                                                            <span>03:00 PM</span>
                                                            <span>17 Oct, 2020</span>
                                                        </p>
                                                        <p class="right">
                                                            <span>$160.48</span>
                                                            <span>Withdraw</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="left">
                                                            <span>01:09 PM</span>
                                                            <span>15 Oct, 2021</span>
                                                        </p>
                                                        <p class="right">
                                                            <span>$106.58</span>
                                                            <span>Withdraw</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="left">
                                                            <span>04:00 PM</span>
                                                            <span>12 Oct, 2020</span>
                                                        </p>
                                                        <p class="right">
                                                            <span>$176.58</span>
                                                            <span>Withdraw</span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="left">
                                                            <span>06:00 PM</span>
                                                            <span>25 Oct, 2020</span>
                                                        </p>
                                                        <p class="right">
                                                            <span>$167.85</span>
                                                            <span>Withdraw</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="cancel" role="tabpanel"
                                            aria-labelledby="cancel-tab">
                                            <div class="main-area">
                                                <div class="transfer-area">
                                                    <p>Set a transfer limit for DurraFX payment Card</p>
                                                    <p class="mdr">Transfer Limit</p>
                                                </div>
                                                <form action="#">
                                                    <div class="input-area">
                                                        <input class="xxlr" placeholder="400.00" type="number">
                                                        <select>
                                                            <option value="1">USD</option>
                                                            <option value="2">USD</option>
                                                            <option value="3">USD</option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        class="bottom-area d-flex align-items-center justify-content-between">
                                                        <a href="javascript:void(0)" class="cmn-btn cancel">Cancel and
                                                            Back</a>
                                                        <a href="javascript:void(0)" class="cmn-btn">Confirm Transfer
                                                            Limit</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Card Popup start -->

    <!-- Add Card Popup start -->
    <div class="add-card">
        <div class="container-fruid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="modal fade" id="addcardMod" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header justify-content-between">
                                    <h6>Add Card</h6>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                </div>
                                <form action="#">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12">
                                            <div class="single-input">
                                                <label for="cardNumber">Card Number</label>
                                                <input type="text" id="cardNumber"
                                                    placeholder="5890 - 6858 - 6332 - 9843">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="single-input">
                                                <label for="cardHolder">Card Holder</label>
                                                <input type="text" id="cardHolder" placeholder="Alfred Davis">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label for="month">Month</label>
                                                <input type="text" id="month" placeholder="12">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="single-input">
                                                <label for="year">Year</label>
                                                <input type="text" id="year" placeholder="2025">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="btn-border w-100">
                                                <button class="cmn-btn w-100">Add Card</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Card Popup start -->

    <!-- Transactions Popup start -->
    <div class="transactions-popup">
        <div class="container-fruid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="modal fade" id="transactionsMod" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header justify-content-between">
                                    <h5>Transaction Details</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
                                </div>
                                <div class="main-content">
                                    <div class="row">
                                        <div class="col-sm-5 text-center">
                                            <div class="icon-area">
                                                <img src="assets/images/icon/transaction-details-icon.png" alt="icon">
                                            </div>
                                            <div class="text-area">
                                                <h6>Envato Pty Ltd</h6>
                                                <p>16 Jan 2022</p>
                                                <h3>717.14 USD</h3>
                                                <p class="com">Completed</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-7">
                                            <div class="right-area">
                                                <h6>Transaction Details</h6>
                                                <ul class="payment-details">
                                                    <li>
                                                        <span>Payment Amount</span>
                                                        <span>718.64 USD</span>
                                                    </li>
                                                    <li>
                                                        <span>Fee</span>
                                                        <span>1.50 USD</span>
                                                    </li>
                                                    <li>
                                                        <span>Total Amount</span>
                                                        <span>717.14 USD</span>
                                                    </li>
                                                </ul>
                                                <ul class="payment-info">
                                                    <li>
                                                        <p>Payment From</p>
                                                        <span class="mdr">Envato Pty Ltd</span>
                                                    </li>
                                                    <li>
                                                        <p>Payment Description</p>
                                                        <span class="mdr">Envato Feb 2022 Member Payment</span>
                                                    </li>
                                                    <li>
                                                        <p>Transaction  ID:</p>
                                                        <span class="mdr">6559595979565959895559595</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Transactions Popup start -->
  </div>
</template>
<script>

import Chart from "chart.js";
import core_function from "@/services/core_function";
export default {
  name: "dashboard-page",
  components: {

  },
  data (){
    return {
      dashboard_data: {},
      config: {
        type: 'bar',
        data: [1,2,3,4,5,6],
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        },
      },
      trades: [],
      dataCount: 0,
      page: 1,
      dataPerPage: 15,
      search: "",
    }
  },
  methods: {
    async getTrades(){
      try{
        let response = await core_function.loadTrades(this.page, this.dataPerPage, this.search)
        this.trades = response.data['results']
        this.dataCount = response.data['count']
      }catch (err){
        console.log(err)
      }
    },
    createChart(labels, data){
      const ctx = document.getElementById('myChart');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [{
            label: '# of Trades',
            data: data,
            borderWidth: 1,
             borderColor: '#36A2EB',
             backgroundColor: '#9BD0F5',
            height: 100,
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        },
      });
    },
    async getDashboardData(){
      try{
        let response = await core_function.dashboardData()
        this.dashboard_data = response.data
        this.createChart(response.data['current_year'])
      }catch (err){
        console.log("here")
        console.log(err)
      }
    }
  },
  mounted() {
    this.getDashboardData()
    this.getTrades()
  }
};
</script>
<style scoped>
.dashboard-section .acc-details {
    background: green;
    border-radius: 10px;
}

.dashboard-section .acc-details .bottom-area {
    background: green;
}

.dashboard-section .acc-details .top-area h5, .dashboard-section .acc-details .top-area h2, .dashboard-section .acc-details .top-area h4 {
    color: var(--bs-dark);
}
.dashboard-section .acc-details .top-area .receive span {
    color: white;
    font-size: inherit;
    font-weight: inherit;
}
.dashboard-section .acc-details .bottom-area .cmn-btn {
    margin-bottom: 15px;
    margin-right: 15px;
    background-color: transparent;
    border: 1px solid var(--bs-white);
    color: var(--bs-dark);
}

.card {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    color: white;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.l-bg-cherry {
    background: linear-gradient(to right, #493240, #493240) !important;
    color: #fff;
}

.l-bg-blue-dark {
    background: linear-gradient(to right, #373b44, #373b44) !important;
    color: #fff;
}

.l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #0a504a) !important;
    color: #fff;
}

.l-bg-orange-dark {
    background: linear-gradient(to right, #a86008, #a86008) !important;
    color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
    color: white;
}

.card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #fff;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.l-bg-orange {
    background: linear-gradient(to right, #f9900e, #ffba56) !important;
    color: #fff;
}

.l-bg-cyan {
    background: linear-gradient(135deg, #289cf5, #84c0ec) !important;
    color: #fff;
}
th {
  background-color: #14d18f;
  padding: 4px 7px !important;
  color: black !important;
}

.dashboard-section .transactions-area .section-text {
    padding: 30px;
    border-bottom: none;
    margin-bottom: 0px;
}


td {
  padding: 4px 7px !important;
}

table, th, td {
  border-top: 1px solid lightgray !important;
  border-bottom: 1px solid lightgray !important;
}
th {
  border-left: 1px solid lightgray !important;
}
table {
  border-left: 1px solid lightgray !important;
  border-right: 1px solid lightgray !important;
}
</style>